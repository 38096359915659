const state = () => ({
    articles: []
})

// getters
const getters = {
    getArticles: (state) => {
        // getArticles: (state, getters, rootState) => {
        return state.articles;
    },
}

// mutations
const mutations = {
    setArticles(state, articles) {
        state.articles = JSON.parse(JSON.stringify(articles));
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}