
let mutations = {
    updateUser(state, user) {
        Object.assign(state.user, user);
    },
    updateUserPoints(state, userPoints) {
        state.userPoints = Array.from(new Set(userPoints));
    },
    updateUserInfo(state, userInfo) {
        Object.assign(state.userInfo, userInfo);
    },
    updateCurrentPath(state, currentPath) {
        state.currentPath = currentPath.slice(1);
    },
    updateIsFinishQuestions(state, val) {
        state.isFinishQuestions = val;
    },
    updateIsQANotExist(state, val) {
        state.isQANotExist = val;
    },
    updateCampaign(state, val) {
        Object.assign(state.campaign, val);
    },

    setMuteNotifications(state, mute) {
        state.muteNotifications = mute;
        // Object.assign(state.muteNotifications, mute);
    }
};

export default mutations